<template>
  <span class="small-box" :class="{ active: clockStatus > 0, absence: inscription.absence, }">
    <span class="clock-prefix no-print" v-if="showResult">
      <i class="fa fa-ellipsis-h" v-if="!success && !error"></i>
      <i class="fa fa-check" v-if="success"></i>
      <i class="fa fa-warning" v-if="error"></i>
    </span>
    <span class="clock-prefix no-print" v-if="!isButtonActive && !showResult" @click="prefixClicked()">
      <i class="fa fa-eye" style="opacity: 0.3"></i>
    </span>
    <span v-if="isButtonActive && showClock && !showResult" class="clock-prefix no-print" @click="prefixClicked()">
      <i class="far fa-clock-o" v-if="clockStatus === 1" title="en direct" v-b-tooltip></i>
      <i class="fa fa-history" v-else title="à posteriori" v-b-tooltip></i>
    </span>
    <div class="arrived-at item" v-if="showArrivedAt" @click.prevent="clockClicked(Opening)">
      {{ inscription.arrivedAt | hour | defaultValue('') }}
    </div>
    <div class="left-at item" v-if="showLeftAt"  @click.prevent="clockClicked(Closing)">
      {{ inscription.leftAt | hour | defaultValue('') }}
    </div>
    <div v-if="separator"></div>
    <div class="clock-prefix" v-if="separator">
      <i class="fa fa-check fa-transparent no-print"></i>
    </div>
    <div class="arrived-at2 item" v-if="showArrivedAt2" @click.prevent="clockClicked(Opening2)">
      {{ inscription.arrivedAt2 | hour | defaultValue('') }}
    </div>
    <div class="left-at2 item" v-if="showLeftAt2" @click.prevent="clockClicked(Closing2)">
      {{ inscription.leftAt2 | hour | defaultValue('') }}
    </div>
    <div class="arrived-at3 item" v-if="showArrivedAt3" @click.prevent="clockClicked(Opening3)">
      {{ inscription.arrivedAt3 | hour | defaultValue('') }}
    </div>
    <div class="left-at3 item" v-if="showLeftAt3" @click.prevent="clockClicked(Closing3)">
      {{ inscription.leftAt3 | hour | defaultValue('') }}
    </div>
    <b-modal class="set-hour-modal" :id="getId()" ok-title="Oui" cancel-title="Non" @ok="resetHour">
      <template v-slot:modal-title>
        <b><i class="fa fa-question-circle"></i> {{ getModalLabel(clickedMoment) }}</b>
      </template>
      <div>
        Une heure est déjà défini. Voulez-vous réinitialiser la valeur?
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import moment from 'moment'
import { DayMoments } from '@/types/youth'
import { addMinutes, removeMinutes } from '@/utils/time'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getSafeClocking } from '@/utils/clocking'

export default {
  name: 'TimeSelector',
  mixins: [BackendMixin],
  props: {
    inscription: Object,
    openingHours: Object,
    clockStatus: Number,
  },
  data() {
    return {
      randomId: getRandomId(),
      showResult: false,
      success: false,
      error: false,
      clickedMoment: DayMoments.None,
      Opening: DayMoments.Opening,
      Closing: DayMoments.Closing,
      Opening2: DayMoments.Opening2,
      Closing2: DayMoments.Closing2,
      Opening3: DayMoments.Opening3,
      Closing3: DayMoments.Closing3,
      clickingInProgress: DayMoments.None,
      clickTimeout: 0,
    }
  },
  watch: {
    inscription: function() { this.resetClickInProgress() },
    clockStatus: function() { this.resetClickInProgress() },
  },
  computed: {
    seance() {
      return this.inscription.seance
    },
    isButtonActive() {
      return this.clockStatus !== 0
    },
    showClock() {
      let active = this.showArrivedAt || this.showLeftAt || this.showArrivedAt2 || this.showLeftAt2
      return active && this.clockStatus > 0
    },
    dayMoments() {
      let dayMoments = []
      if (this.showArrivedAt) {
        dayMoments.push(DayMoments.Opening)
      }
      if (this.showLeftAt) {
        dayMoments.push(DayMoments.Closing)
      }
      if (this.showArrivedAt2) {
        dayMoments.push(DayMoments.Opening2)
      }
      if (this.showLeftAt2) {
        dayMoments.push(DayMoments.Closing2)
      }
      return dayMoments
    },
    showArrivedAt() {
      return this.inscription.showArrivedAt(this.openingHours)
    },
    showLeftAt() {
      return this.inscription.showLeftAt(this.openingHours)
    },
    showArrivedAt2() {
      return this.inscription.showArrivedAt2(this.openingHours)
    },
    showLeftAt2() {
      return this.inscription.showLeftAt2(this.openingHours)
    },
    showArrivedAt3() {
      return this.inscription.showArrivedAt3(this.openingHours)
    },
    showLeftAt3() {
      return this.inscription.showLeftAt3(this.openingHours)
    },
    separator() {
      return this.showArrivedAt && this.showLeftAt && this.showArrivedAt2 && this.showLeftAt2
    },
    step() {
      return this.openingHours.step
    },
    commonArrivalAndDeparture() {
      return this.openingHours.commonArrivalAndDeparture
    },
    fullDay() {
      const seance = this.inscription.seance
      return seance.morning && seance.lunch && seance.afternoon
    },
  },
  methods: {
    ...mapActions(['addError']),
    resetClickInProgress() {
      this.clickingInProgress = DayMoments.None
      this.resetDelay()
    },
    getId() {
      return this.randomId
    },
    getModalLabel(dayMoment) {
      switch (dayMoment) {
        case DayMoments.Opening:
          return this.openingHours.openingAt2 ? 'Heure d\'ouverture matin' : 'Heure d\'ouverture'
        case DayMoments.Closing:
          return this.openingHours.closingAt2 ? 'Heure de fermeture matin' : 'Heure de fermeture'
        case DayMoments.Opening2:
          return 'Heure d\'ouverture soir'
        case DayMoments.Closing2:
          return 'Heure de fermeture soir'
        case DayMoments.Openin3:
          return 'Heure d\'ouverture soirée'
        case DayMoments.Closing3:
          return 'Heure de fermeture soirée'
      }
      return ''
    },
    getOpeningAt() {
      return this.openingHours.openingAt
    },
    getClosingAt() {
      let closingAt = this.openingHours.closingAt
      if (this.seance.lunch && !this.seance.afternoon && this.openingHours.lunchEndsAt) {
        closingAt = this.openingHours.lunchEndsAt
      }
      return closingAt
    },
    getOpeningAt2() {
      let openingAt2 = this.openingHours.openingAt2
      if (this.seance.lunch && !this.seance.morning && this.openingHours.lunchStartsAt) {
        openingAt2 = this.openingHours.lunchStartsAt
      }
      return openingAt2
    },
    getClosingAt2() {
      return this.openingHours.closingAt2
    },
    getOpeningAt3() {
      return this.openingHours.openingAt3
    },
    getClosingAt3() {
      return this.openingHours.closingAt3
    },
    getCurrentHour(dayMoment) {
      // Mode Direct
      let currentHour = moment().format('HH:mm')
      return getSafeClocking(this.openingHours, this.inscription, currentHour, dayMoment)
    },
    getDefaultHourValue(dayMoment) {
      let currentHour = ''
      switch (dayMoment) {
        case DayMoments.Opening:
          currentHour = this.getOpeningAt()
          break
        case DayMoments.Closing:
          currentHour = this.getClosingAt()
          break
        case DayMoments.Opening2:
          currentHour = this.getOpeningAt2()
          break
        case DayMoments.Closing2:
          currentHour = this.getClosingAt2()
          break
        case DayMoments.Opening3:
          currentHour = this.getOpeningAt3()
          break
        case DayMoments.Closing3:
          currentHour = this.getClosingAt3()
          break
      }
      return currentHour
    },
    getNewHourValue(dayMoment) {
      let currentHour = ''
      let plus = false
      switch (dayMoment) {
        case DayMoments.Opening:
          currentHour = this.inscription.arrivedAt
          plus = true
          break
        case DayMoments.Closing:
          currentHour = this.inscription.leftAt
          break
        case DayMoments.Opening2:
          currentHour = this.inscription.arrivedAt2
          plus = true
          break
        case DayMoments.Closing2:
          currentHour = this.inscription.leftAt2
          break
        case DayMoments.Opening3:
          currentHour = this.inscription.arrivedAt3
          plus = true
          break
        case DayMoments.Closing3:
          currentHour = this.inscription.leftAt3
          break
      }
      if (plus) {
        currentHour = addMinutes(currentHour, this.step)
      } else {
        currentHour = removeMinutes(currentHour, this.step)
      }
      return getSafeClocking(this.openingHours, this.inscription, currentHour, dayMoment)
    },
    getHour(dayMoment) {
      switch (dayMoment) {
        case DayMoments.Opening:
          return this.inscription.arrivedAt
        case DayMoments.Closing:
          return this.inscription.leftAt
        case DayMoments.Opening2:
          return this.inscription.arrivedAt2
        case DayMoments.Closing2:
          return this.inscription.leftAt2
        case DayMoments.Opening3:
          return this.inscription.arrivedAt3
        case DayMoments.Closing3:
          return this.inscription.leftAt3
      }
      return ''
    },
    resetHour() {
      this.setHour('', this.clickedMoment)
      this.clickedMoment = DayMoments.None
      this.saveValues()
    },
    resetDelay() {
      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout)
        this.clickTimeout = 0
      }
    },
    waitForDelay() {
      this.clickTimeout = setTimeout(
        () => {
          this.resetClickInProgress()
          this.saveValues()
        },
        3000
      )
    },
    setHour(value, dayMoment) {
      this.inscription.clockingInProgress = true
      switch (dayMoment) {
        case DayMoments.Opening:
          this.inscription.arrivedAt = value
          if (this.commonArrivalAndDeparture) {
            if (this.fullDay) {
              const closingAt2 = this.getClosingAt2()
              if (closingAt2 && !this.inscription.leftAt2 && this.inscription.arrivedAt) {
                this.inscription.leftAt2 = closingAt2
              }
            } else {
              const closingAt = this.getClosingAt()
              if (closingAt && !this.inscription.leftAt && this.inscription.arrivedAt) {
                this.inscription.leftAt = closingAt
              }
            }
          }
          break
        case DayMoments.Closing:
          this.inscription.leftAt = value
          if (this.commonArrivalAndDeparture) {
            const openingAt = this.getOpeningAt()
            if (openingAt && !this.inscription.arrivedAt && this.inscription.leftAt) {
              this.inscription.arrivedAt = openingAt
            }
          }
          break
        case DayMoments.Opening2:
          this.inscription.arrivedAt2 = value
          if (this.commonArrivalAndDeparture) {
            const closingAt2 = this.getClosingAt2()
            if (closingAt2 && !this.inscription.leftAt2 && this.inscription.arrivedAt2) {
              this.inscription.leftAt2 = closingAt2
            }
          }
          break
        case DayMoments.Closing2:
          this.inscription.leftAt2 = value
          if (this.commonArrivalAndDeparture) {
            if (this.fullDay) {
              const openingAt = this.getOpeningAt()
              if (openingAt && !this.inscription.arrivedAt && this.inscription.leftAt2) {
                this.inscription.arrivedAt = openingAt
              }
            } else {
              const openingAt2 = this.getOpeningAt2()
              if (openingAt2 && !this.inscription.arrivedAt2 && this.inscription.leftAt2) {
                this.inscription.arrivedAt2 = openingAt2
              }
            }
          }
          break
        case DayMoments.Opening3:
          this.inscription.arrivedAt3 = value
          break
        case DayMoments.Closing3:
          this.inscription.leftAt3 = value
          break
      }
    },
    async saveValues() {
      let data = {
        arrived_at: this.inscription.arrivedAt || null,
        left_at: this.inscription.leftAt || null,
        arrived_at2: this.inscription.arrivedAt2 || null,
        left_at2: this.inscription.leftAt2 || null,
        arrived_at3: this.inscription.arrivedAt3 || null,
        left_at3: this.inscription.leftAt3 || null,
      }
      let url = '/api/youth/set-inscription-hours/' + this.inscription.id + '/'
      const backendApi = new BackendApi('post', url)
      try {
        await backendApi.callApi(data)
        this.inscription.clockingInProgress = false
        this.success = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
        this.error = true
      }
      setTimeout(
        () => {
          this.showResult = false
          this.success = false
          this.error = false
        },
        1000
      )
    },
    clockClicked(dayMoment) {
      if (this.clockStatus === 0) {
        return
      }
      this.showResult = true
      if (this.clickingInProgress === dayMoment) {
        this.setHour(this.getNewHourValue(dayMoment), dayMoment)
        this.resetDelay()
        this.waitForDelay()
      } else {
        if (this.getHour(dayMoment)) {
          this.clickedMoment = dayMoment
          this.$bvModal.show(this.getId())
        } else {
          if (this.clockStatus === 1) {
            // En mode direct : un clic afficher l'heure courante arrondie
            this.setHour(this.getCurrentHour(dayMoment), dayMoment)
            this.saveValues()
          } else {
            // En mode 'à posteriori', on clique X fois pour ajouter/soustraire step (par défaut 30) minutes
            // à l'heure d'ouverture/fermeture
            this.setHour(this.getDefaultHourValue(dayMoment), dayMoment)
            this.clickingInProgress = dayMoment
            this.waitForDelay()
          }
        }
      }
    },
    prefixClicked() {
      this.$emit('prefixClicked', {})
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.small-box > .item {
  display: inline-block;
  margin: 2px 0 0 2px;
  font-size: 12px;
  padding: 2px 2px;
  min-width: 45px;
  min-height: 30px;
  vertical-align: bottom;
  line-height: 24px;
  text-align: center;
  cursor: not-allowed;
  user-select: none;
}
.small-box.absence .item {
  opacity: 0.2;
}
.separator {
  display: block;
}
.small-box.active > div {
  cursor: pointer;
}
.small-box:empty:before {
  content: "...";
}
.clock-prefix {
  display: inline-block;
  margin-right: 1px;
  margin-left: 3px;
  margin-top: 8px;
  vertical-align: top;
}
.fa-transparent {
  color: transparent;
}
</style>
