<template>
  <b-row class="set-time-selector">
    <b-col @click.prevent="status = 0" :class="{ selected: status === 0 }">
      <i class="fa fa-eye"></i> Lecture seule
    </b-col>
    <b-col @click.prevent="status = 1" :class="{ selected: status === 1 }" v-if="!hideDirect">
      <i class="far fa-clock-o"></i> En direct
    </b-col>
    <b-col @click.prevent="status = 2" :class="{ selected: status === 2 }">
      <i class="fa fa-history"></i> À posteriori
    </b-col>
  </b-row>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'SetTimeSelector',
  prop: ['value'],
  props: {
    value: Number,
    hideDirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: 0,
    }
  },
  computed: {
  },
  watch: {
    value: function() {
      this.status = this.value
    },
    status: function() {
      this.$emit('input', this.status || 0)
    },
  },
  methods: {
  },
  mounted() {
    this.status = this.value || 0
  },
}
</script>
<style lang="less">
.set-time-selector {
  padding: 0;
  margin: 0;
}
.set-time-selector > div {
  padding: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  border: solid 1px #000;
}
.set-time-selector > div.selected {
  font-weight: bolder;
  background: #888;
  color: #fff;
}
</style>
